window.unifiedHelpComponent = function() {
  return {
    childPinned: this.$persist(0).using(sessionStorage),
    showWidth: this.$persist(0).using(sessionStorage),
    dualPanel: this.$persist(0).using(sessionStorage),

    async init() {

      // If any child is pinned then show the help
      if(this.childPinned) {
        jQuery('body').addClass('showing-help');
      }

      this.updateWidth();
      this.updatePanelLayout();

      // Enable animations (gives initial snap open)
      setTimeout(function() {
        jQuery('body').addClass('smooth-help');
        jQuery(window).trigger('resize');
      }, 100);

      // Enable copy to clipboard for help IDs
      var clipboardJs = new ClipboardJS(".unified-help-copy-helpid", {});
      clipboardJs.on("success", function(e) {
        var ref = e.text.match(/^#/) ? 'reference' : 'link';
        Notifications.showSuccessMessage("Help " + ref + " <strong>" + e.text + "</strong> copied to the clipboard.");
      });

    },
    async showHelpEvent() {
      if(!jQuery('body').hasClass('showing-help')) {
        this.toggleDisplay();
      }
    },
    async toggleDisplay() {
      if(jQuery('body').hasClass('showing-help')) {
        jQuery('body').removeClass('showing-help');
      }
      else {
        jQuery('body').addClass('showing-help');

        // If wide
        if(this.showWide) {
          jQuery('body').addClass('wide-help');
        }
      }

      // Trigger a resize event so that nav updates
      setTimeout(function() {
        jQuery(window).trigger('resize');
      }, 500);
    },
    async setWidthNarrow() {
      this.showWidth = 0;
      this.updateWidth();
    },
    async setWidthWide() {
      this.showWidth = 1;
      this.updateWidth();
    },
    async setWidthFull() {
      this.showWidth = 2;
      this.updateWidth();
    },
    async updateWidth() {
      if(this.showWidth === 0) {
        jQuery('body').removeClass('wide-help');
        jQuery('body').removeClass('full-help');
      } else if(this.showWidth === 1) {
        jQuery('body').removeClass('full-help');
        jQuery('body').addClass('wide-help');
      } else {
        jQuery('body').removeClass('wide-help');
        jQuery('body').addClass('full-help');
      }
    },
    async closeSidebar() {
      this.toggleDisplay();
    },
    async setSinglePanel() {
      this.dualPanel = 0;
      this.updatePanelLayout();

      self.$dispatch('unifiedhelp-article-single-panel');
    },
    async setDualPanelV() {
      this.dualPanel = 1;
      this.updatePanelLayout();
    },
    async setDualPanelH() {
      this.dualPanel = 2;
      this.updatePanelLayout();
    },
    async updatePanelLayout() {
      if(this.dualPanel === 2) {
        jQuery('.unified-help-sidebar').addClass('dual-panel-h');
      } else {
        jQuery('.unified-help-sidebar').removeClass('dual-panel-h');
      }
    },
  };
};
