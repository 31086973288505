window.unifiedHelp = {

  showHelp: function() {
    // Show the help panel
    var event = new CustomEvent('unifiedhelp-showhelppanel');
    window.dispatchEvent(event);
  },

  /**
   * Set the search term and run a search.
   *
   * @param {String} term
   * @param {String} altTerm
   */
  searchByTerm: function(term, altTerm) {
    var event = new CustomEvent('unifiedhelp-showbyterm', {
      detail: {
        term: term,
        altTerm: altTerm,
        inPanel: 1
      }
    });
    window.dispatchEvent(event);

    // Show the help panel
    var event = new CustomEvent('unifiedhelp-showhelppanel');
    window.dispatchEvent(event);
  },

  /**
   * Show a help article based off it's reference.
   *
   * @param {String} ref
   */
  showByRef: function(ref) {
    var event = new CustomEvent('unifiedhelp-showbyref', {
      detail: {
        ref: ref,
        inPanel: 1
      }
    });
    window.dispatchEvent(event);

    // Show the help panel
    var event = new CustomEvent('unifiedhelp-showhelppanel');
    window.dispatchEvent(event);
  },

  /**
    * Get the current URI in a consistent form.
    *
    * @param {boolean} includeId If true include the content id in the URI
    * @return {string} The URI
    */
  getURI: function(includeId) {
    // Build the URI were on, 1st segment + op & section if present
    var pairs = window.location.search.substr(1).split('&'),
    kv = [],
        url = '';
    for(var i=0;i<pairs.length;i++) {
      var v = pairs[i].split('=');

      if(i == 0) {
        url += '?' + v[0];
      }
      else {
        kv[v[0]] = pairs[i];
      }
    }

    if(kv['op']) {
      url += '&' + kv['op'];
    }
    if(kv['section']) {
      url += '&' + kv['section'];
    }
    if(kv['cl']) {
      url += '&' + kv['cl'];
    }
    if(includeId && kv['id']) {
      url += '&' + kv['id'];
    }

    return url;
  },

  /**
   * Detect images being added to the content and auto click the 1st one to start image replace process.
   * @param {String} html
   */
  fastChangeImage: function(html) {
    var part1 = html.split(/(?=<img[^>]+>)/g),
        parts = [],
        i, n,
        hasImage = false;

    // Break apart on images
    for(i=0;i<part1.length;i++) {
      if(part1[i].match(/^<img/)) {
        var s = part1[i].split(/(?=>)/g);
        hasImage = true;
        for(n=0;n<s.length;n++) {
          parts.push(s[n]);
        }
      }
      else {
        parts.push(part1[i]);
      }
    }

    if(hasImage) {
      // Tag the 1st image
      for(i=0;i<parts.length;i++) {
        if(parts[i].match(/^<img/)) {
          // If it has a class then amend
          if(parts[i].match(/class=/)) {
            parts[i].replace('class="', 'class="unified-help-auto-click ');
            break;
          }
          // Else append
          else {
            parts[i] += ' class="unified-help-auto-click" ';
            break;
          }
        }
      }

      // Put the HTML back together
      html = parts.join('');

      // Set a task to trigger the click
      setTimeout(function() {
        jQuery('#unified-help-edit .unified-help-auto-click')
          .addClass('elm-active')
          .trigger('click')
          .removeClass('unified-help-auto-click');
        jQuery('#divImageTool .image-link').trigger('click');
      }, 100);
    }

    return html;
  },
};

var unifiedHelpContentBuilder = {
  isContentBuilderLoadDone: false,
  builder: null,
  enabledForPanels: [],

  create: function(panelId) {
    // If the panelID is already in the enabled list then do nothing
    if(this.enabledForPanels.includes(panelId)) {
      return;
    }

    Shortcut.ignore(true);

    // Add enabled panel to the panel list
    this.enabledForPanels.push(panelId);

    // Track the load of this globally as we only need one copy loaded
    if(!this.isContentBuilderLoadDone) {
      const self = this;

      this.isContentBuilderLoadDone = true;

      var link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = '/vendor_assets/fortix/contentbuilder/contentbuilder.css';
      link.media = 'all';
      document.getElementsByTagName('head')[0].appendChild(link);

      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/vendor_assets/fortix/contentbuilder/lang/en.js';
      document.getElementsByTagName('head')[0].appendChild(script);

      script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/vendor_assets/fortix/contentbuilder/contentbuilder.min.js';
      script.onload = function() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '/vendor_assets/fortix/contentbuilder/assets/minimalist-blocks/content.js';
        script.onload = function() {
          self.createBuilder();
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    }
    else {
      this.createBuilder();
    }
  },

  destroy: function(panelId) {
    // If the panel doesn't have an editor then do nothing
    if(!this.enabledForPanels.includes(panelId)) {
      return;
    }

    // Remove the panel from the enabled list
    this.enabledForPanels = this.enabledForPanels.filter(function(item) {
      return item != panelId;
    });

    // If no more panels are enabled then destroy the builder
    if(!this.enabledForPanels.length) {
      this.destroyBuilder();
      Shortcut.ignore(false);
    }
    // Otherwise rebuild the builder
    else {
      this.createBuilder();
    }
  },

  createBuilder: function() {

    // Destroy the current builder if any
    this.destroyBuilder();

    // Build a selectors string for the enabled panels
    var selectors = this.enabledForPanels.map(function(panelId) {
      return '.unified-help-panel-' + panelId + ' .unified-help-editor-container';
    }).join(', ');

    // Create a new builder
    this.builder = new ContentBuilder({
      scriptPath: '/vendor_assets/fortix/contentbuilder/',
      assetPath: '/vendor_assets/fortix/contentbuilder/assets/',
      fontAssetPath: '/vendor_assets/fortix/contentbuilder/assets/fonts/',
      snippetData: '/vendor_assets/fortix/contentbuilder/assets/minimalist-blocks/snippetlist.html',
      row: 'row',
      cols: ['col-md-1', 'col-md-2', 'col-md-3', 'col-md-4', 'col-md-5', 'col-md-6', 'col-md-7', 'col-md-8', 'col-md-9', 'col-md-10', 'col-md-11', 'col-md-12'],
      container: selectors, //'.unified-help-editor-container',
      builderMode: '',
      toolbar: 'top',
      columnTool: true,
      rowTool: 'right',
      elementTool: true,
      snippetAddTool: true,
      outlineMode: '',
      rowcolOutline: true,
      outlineStyle: '',
      elementHighlight: true,
      snippetOpen: false,
      toolStyle: '',
      snippetsSidebarDisplay: 'auto',
      animateModal: false,
      clearPreferences: true,
      onAdd: unifiedHelp.fastChangeImage,
      largerImageHandler: '/freedom3/UnifiedHelp/largeImageHandler',
      imageEmbed: false,
    });

    // trigger the editor to fully load
    var self = this;
    setTimeout(function() {
      self.enabledForPanels.map(function(panelId) {
        unifiedHelpContentBuilder.builder.loadHtml("", document.querySelector('.unified-help-panel-' + panelId + ' .unified-help-content-notes'));
      });
    }, 0);
  },

  destroyBuilder: function() {
    if(this.builder && this.builder.builderStuff) {
      this.builder.destroy();
      this.builder = null;
    }
  },
};

window.unifiedHelpComponentArticle = function() {
  // Get the panel ID, we use this to make the persist keys unique & know which panel the show article event is for
  const panelId = this.$el.getAttribute('data-unified-help-panel-id');

  return {
    isLoading: true,
    hasLoaded: false,
    panelId: panelId,
    contentId:this.$persist(0).as('_x_article_contentId_' + panelId).using(sessionStorage),
    isGlobal: this.$persist(false).as('_x_article_isGlobal_' + panelId).using(sessionStorage),
    article: {
      content: {
        title: '',
        notes: '',
        document: '',
        internal: '',

        globalTitle: '',
        globalDocumentGoogleURL: '',
        globalDocument: '',
        globalInternalGoogleURL: '',
        globalInternal: '',

        uris: [],
        moreLike: [],

        canEdit: false,
        canEditGlobal: false,
      },
    },
    refURL: '',
    pageLinkURL: '',
    dashboardLinkURL: '',
    createdBy: '',
    updatedBy: '',
    createdByGlobal: '',
    updatedByGlobal: '',
    history: this.$persist([]).as('_x_article_history_' + panelId).using(sessionStorage),
    isLinkedHere: false,
    inEditMode: false,
    builder: null,
    original: {
      title: '',
      globalTitle: '',
    },
    lockTimer: null,
    lockInstance: 0,
    lockGlobal: 0,
    addGlobalAllShow: false,
    addGlobalInternalShow: false,
    academyFolders: [],
    academyFortixFolder: "",
    academyInternalFolder: "",
    isCreatingDoc: false,

    async init() {

      // If the history is a string then JSON decode and convert it to an array
      if(typeof this.history == 'string') {
        this.history = JSON.parse(this.history);
      }

      if(this.panelId == 1 && window.location.hash.match(/^#help_\d+[CFcf]$/)) {
        this.showByRef(window.location.hash.replace(/^#help_/, ''));
        jQuery('body').addClass('showing-help');
      }
      else if(this.contentId) {
        this.showArticle(this.contentId, this.isGlobal);
      }
      this.updateIsLinkedHere();
    },
    updateIsLinkedHere() {
      this.isLinkedHere = this.article.content.uris.includes(unifiedHelp.getURI(false));
    },
    async inlineUnlinkTopic(url) {
      var self = this;

      jQuery(this.$event.srcElement).find('i').removeClass('fa-unlink').addClass('fa-spinner fa-spin');

      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxUnlink',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: {
            contentId: parseInt(self.contentId),
            global: self.isGlobal,
            uri: url
          },
          success: function(data) {
            if(data.success) {
              // Remove the uri from the list
              self.article.content.uris = self.article.content.uris.filter(function(item) {
                return item != url;
              });
              self.updateIsLinkedHere();

              jQuery(self.$event.srcElement).find('i').addClass('fa-unlink').removeClass('fa-spinner fa-spin');
              Notifications.showSuccessMessage('Help topic has been unlinked.');
            }
            else {
              jQuery(self.$event.srcElement).find('i').addClass('fa-unlink').removeClass('fa-spinner fa-spin');
              Notifications.showErrorMessage('Help topic could not be unlinked. Please try again later.');
            }
          },
          error: function() {
            jQuery(self.$event.srcElement).find('i').addClass('fa-unlink').removeClass('fa-spinner fa-spin');
          }
        }
      );
    },
    async linkTopic() {
      var self = this,
          url = unifiedHelp.getURI(false);

      jQuery(self.$event.srcElement).find('i').removeClass('fa-link').addClass('fa-spinner fa-spin');

      // Link content
      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxLink',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: {
            contentId: self.contentId,
            global: self.isGlobal,
            uri: url
          },
          success: function(data) {
            jQuery(self.$event.srcElement).find('i').addClass('fa-link').removeClass('fa-spinner fa-spin');

            if(data.success) {
              self.article.content.uris.push(url);
              self.updateIsLinkedHere();
              Notifications.showSuccessMessage('Help topic is now linked here.');
            }
            else {
              Notifications.showErrorMessage('Help topic cloud not be linked here. Please try again later.');
            }
          },
          error: function() {
            jQuery(self.$event.srcElement).find('i').addClass('fa-link').removeClass('fa-spinner fa-spin');
          }
        }
      );
    },
    async unlinkTopic() {
      var self = this,
        url = unifiedHelp.getURI(false);

      jQuery(self.$event.srcElement).find('i').removeClass('fa-unlink').addClass('fa-spinner fa-spin');

      // Link content
      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxUnlink',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: {
            contentId: self.contentId,
            global: self.isGlobal,
            uri: url
          },
          success: function(data) {
            jQuery(self.$event.srcElement).find('i').addClass('fa-unlink').removeClass('fa-spinner fa-spin');

            if(data.success) {
              // Remove the uri from the list
              self.article.content.uris = self.article.content.uris.filter(function(item) {
                return item != url;
              });
              self.updateIsLinkedHere();

              Notifications.showSuccessMessage('Help topic has been unlinked from here.');
            }
            else {
              Notifications.showErrorMessage('Help topic cloud not be unlinked. Please try again later.');
            }
          },
          error: function() {
            jQuery(self.$event.srcElement).find('i').addClass('fa-unlink').removeClass('fa-spinner fa-spin');
          }
        }
      );
    },
    async showArticleFromEvent() {
      // If panelID matches then show and prevent the even propagating
      if(this.panelId == this.$event.detail.inPanel) {
        this.showArticle(this.$event.detail.contentId, this.$event.detail.isGlobal);
        this.$event.preventDefault();
      }
    },
    async articleEditedEvent() {
      // If panel ID isn't ours and the article is the same then reload it
      if(this.panelId != this.$event.detail.panelId && this.contentId == this.$event.detail.contentId && this.isGlobal == this.$event.detail.isGlobal) {
        this.showArticle(this.contentId, this.isGlobal);
      }
    },
    async clickOrZoomImage() {
      var target = jQuery(this.$event.srcElement);

      // If image then zoom it
      var el = target.closest('img');
      if(el.length) {
        // Check not in an a tag
        if(!el.closest('a').length) {
          if(!this.inEditMode) {
            var maxWidth = Math.round(jQuery(window).width() * .9),
                maxHeight = Math.round(jQuery(window).height() * .9);

            jQuery.colorbox({
              className: 'unfiedhelp-overlay',
              html: '<img src="' + el.attr('src') + '" style="max-width: ' + maxWidth + 'px; max-height: ' + maxHeight + 'px;" />',
              onOpen: function () {
                jQuery('#cboxLoadingOverlay').css('background-image', 'url(' + ff3_configuration['ajaxLoaderSrc'] + ')');
              },
            });
          }
        }
      }
      // If a tag
      else if((el = target.closest('a')).length) {
        // If it's a help link then show it
        if(el.attr('href').match(/^#help_\d+[CFcf]$/)) {
          this.$event.preventDefault();
          this.showByRef(el.attr('href').replace(/^#help_/, ''));
        }
      }
    },
    async showByRef(ref) {
      var contentId = parseInt(ref.slice(0, ref.length - 1)),
          isGlobal = ref.slice(ref.length - 1).toLowerCase() == 'f';

      this.showArticle(contentId, isGlobal);
    },
    async goBack() {
      if(this.history.length > 1) {
        this.history.pop();
        var last = this.history[this.history.length - 1];
        this.showArticle(last.contentId, last.isGlobal);
      }
    },
    async showArticle(contentId, isGlobal) {
      this.contentId = contentId;
      this.isGlobal = isGlobal;
      this.isLoading = true;

      jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').removeClass('collapsed');
      jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').show();

      // Kill the editor if it's open
      this.inEditMode = false;
      this.cancelLock();
      unifiedHelpContentBuilder.destroy(this.panelId);

      var self = this,
          uri,
          d;

      if(this.contentId) {
        uri = '/freedom3/UnifiedHelp/ajaxFetchByID';
        d = {
          contentId: this.contentId,
          global: this.isGlobal,
          app_id: sessionStorage.getItem('_unifiedHelp_appId')
        };
      }
      else {
        uri = '/freedom3/UnifiedHelp/ajaxFetchHelp';
        d = {
          uri: unifiedHelp.getURI(false),
          app_id: sessionStorage.getItem('_unifiedHelp_appId')
        };
      }

      // Load help content
      jQuery.ajax(
        uri,
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: d,

          success: function(data) {

            // Save the history
            var contentId, isGlobal;

            self.original.title = data.content.contentId ? data.content.title : '';
            self.original.globalTitle = data.content.globalContentId ? data.content.globalTitle : '';

            if(!data.content.contentId) {
              contentId = data.content.globalContentId;
              isGlobal = true;
            } else {
              contentId = data.content.contentId;
              isGlobal = false;
            }

            // If last item not the same as this then add it
            if(!self.history.length || self.history[self.history.length - 1].contentId != contentId || self.history[self.history.length - 1].isGlobal != isGlobal) {
              self.history.push({ contentId: contentId, isGlobal: isGlobal});
            }

            // If history longer than 25 items then remove the first
            if(self.history.length > 25) {
              self.history.shift();
            }

            self.article = data
            if(!self.article.content.globalDocumentGoogleURL) {
              self.article.content.globalDocumentGoogleURL = '';
            }
            if(!self.article.content.globalInternalGoogleURL) {
              self.article.content.globalInternalGoogleURL = '';
            }

            var domain = window.location.href.replace(/\?.*$/, '');
            if(sessionStorage.getItem('_unifiedHelp_appUrl')) {
              domain = 'https://' + sessionStorage.getItem('_unifiedHelp_appUrl');
            }

            var thisPage = domain.replace(/\/$/, '') + '/' + unifiedHelp.getURI(true),
                welcomePage = domain.replace(/\/$/, '') + '/?welcome';

            if(!data.content.contentId) {
              self.refURL = '#help_' + data.content.globalContentId + 'F';
              self.pageLinkURL = thisPage + '#help_' + data.content.globalContentId + 'F';
              self.dashboardLinkURL = welcomePage + '#help_' + data.content.globalContentId + 'F';
            } else {
              self.refURL = '#help_' + data.content.contentId + 'C';
              self.pageLinkURL = thisPage + '#help_' + data.content.contentId + 'C';
              self.dashboardLinkURL = welcomePage + '#help_' + data.content.contentId + 'C';
            }

            if(data.content.title && data.content.title.length) {
              var createdOn = new Date(data.content.createdAt),
                  updatedOn = new Date(data.content.lastUpdatedAt),
                  days = ['SUN','MON','TUE','WED','THU','FRI','SAT'];

              self.createdBy = data.content.creatorUser.fullName + ', ' + days[createdOn.getDay()] + ' ' + createdOn.getDate() + '/' + (createdOn.getMonth() < 9 ? '0' : '') + (createdOn.getMonth() + 1) + '/' + createdOn.getFullYear() + ', ' +
                (createdOn.getHours() > 12 ? ((createdOn.getHours() - 12) + ':' + (createdOn.getMinutes() < 10 ? '0' : '') + createdOn.getMinutes() + ' PM') : (createdOn.getHours() + ':' + (createdOn.getMinutes() < 10 ? '0' : '') + createdOn.getMinutes() + ' AM'));
              self.updatedBy = data.content.user.fullName + ', ' + days[updatedOn.getDay()] + ' ' + updatedOn.getDate() + '/' + (updatedOn.getMonth() < 9 ? '0' : '') + (updatedOn.getMonth() + 1) + '/' + updatedOn.getFullYear() + ', ' +
                (updatedOn.getHours() > 12 ? ((updatedOn.getHours() - 12) + ':' + (updatedOn.getMinutes() < 10 ? '0' : '') + updatedOn.getMinutes() + ' PM') : (updatedOn.getHours() + ':' + (updatedOn.getMinutes() < 10 ? '0' : '') + updatedOn.getMinutes() + ' AM'));
            }

            if(data.content.globalTitle && data.content.globalTitle.length) {
              var createdOn = new Date(data.content.globalCreatedAt),
                updatedOn = new Date(data.content.globalLastUpdatedAt),
                days = ['SUN','MON','TUE','WED','THU','FRI','SAT'];

              self.createdByGlobal = data.content.globalCreatorUser.fullName + ', ' + days[createdOn.getDay()] + ' ' + createdOn.getDate() + '/' + (createdOn.getMonth() < 9 ? '0' : '') + (createdOn.getMonth() + 1) + '/' + createdOn.getFullYear() + ', ' +
                (createdOn.getHours() > 12 ? ((createdOn.getHours() - 12) + ':' + createdOn.getMinutes() + ' PM') : (createdOn.getHours() + ':' + (createdOn.getMinutes() < 10 ? '0' : '') + createdOn.getMinutes() + ' AM'));
              self.updatedByGlobal = data.content.globalUser.fullName + ', ' + days[updatedOn.getDay()] + ' ' + updatedOn.getDate() + '/' + (updatedOn.getMonth() < 9 ? '0' : '') + (updatedOn.getMonth() + 1) + '/' + updatedOn.getFullYear() + ', ' +
                (updatedOn.getHours() > 12 ? ((updatedOn.getHours() - 12) + ':' + (updatedOn.getMinutes() < 10 ? '0' : '') + updatedOn.getMinutes() + ' PM') : (updatedOn.getHours() + ':' + (updatedOn.getMinutes() < 10 ? '0' : '') + updatedOn.getMinutes() + ' AM'));
            }

            self.updateIsLinkedHere();
            self.isLoading = false;
            self.hasLoaded = true;
          },
          error: function() {
//            jQuery('#unified-help-busy').hide();
          }
        }
      );
    },
    resetArticle() {
      this.isLoading = true;
      this.contentId = 0;
      this.isGlobal = true;
      this.article = {
        content: {
          title: '',
          notes: '',
          document: '',
          internal: '',

          globalTitle: '',
          globalDocumentGoogleURL: '',
          globalDocument: '',
          globalInternalGoogleURL: '',
          globalInternal: '',

          uris: [],
          moreLike: [],

          canEdit: false,
          canEditGlobal: false,
        },
      };
    },
    async articleCreatedEvent() {
      // If panelID matches then show and prevent the even propagating
      if(this.panelId == this.$event.detail.inPanel) {
        this.hasLoaded = true;
        this.newTopic();
        this.$event.preventDefault();
      }
    },
    async newTopic() {

      // Get the list of academy folders
      var self = this;
      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxFetchAcademyFolders',
        {
          method: 'get',
          dataType: 'json',
          async: true,
          success: function(data) {
            self.academyFolders = data;

            self.resetArticle();
            self.article.content.canEdit = self.article.content.canEditGlobal = true;
            self.isLoading = false;
            self.inEditMode = true;
            self.cancelLock();
            self.addGlobalAllShow = this.addGlobalInternalShow = false;
            self.academyFortixFolder = self.academyInternalFolder = '';
            self.isCreatingDoc = self.isCreatingDocInternal = false;

            unifiedHelpContentBuilder.create(self.panelId);

            jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').addClass('collapsed');
            jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').hide();
          }
        }
      );
    },
    async saveAsTopic() {
      this.contentId = 0;
      this.isGlobal = true;

      // If the titles are not edited then append copy
      if(this.article.content.title == this.original.title && this.original.title != '') {
        this.article.content.title += ' - copy';
      }
      if(this.article.content.globalTitle == this.original.globalTitle && this.original.globalTitle != '') {
        this.article.content.globalTitle += ' - copy';
      }

      // Save as normal
      this.editSave(false);
    },
    async showMenu() {
      this.showResults = true;
    },
    async editTopic() {
      var self = this;

      this.addGlobalAllShow = this.addGlobalInternalShow = false;
      this.academyFortixFolder = this.academyInternalFolder = '';
      this.isCreatingDoc = this.isCreatingDocInternal = false;

      unifiedHelpContentBuilder.destroy(this.panelId);
      this.inEditMode = false;
      this.cancelLock();

      jQuery(self.$el).find('i').removeClass('fa-edit').addClass('fa-spinner fa-spin');

      // Get the list of academy folders
      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxFetchAcademyFolders',
        {
          method: 'get',
          dataType: 'json',
          async: true,
          success: function(data) {
            self.academyFolders = data;
          }
        }
      );

      // Request lock
      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxLock',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: {
            contentId: self.article.content.contentId,
            globalContentId: self.article.content.globalContentId,
            app_id: sessionStorage.getItem('_unifiedHelp_appId')
          },
          success: function(lockData) {
            jQuery(self.$el).find('i').addClass('fa-edit').removeClass('fa-spinner fa-spin');

            if(lockData.success) {

              if(!lockData.canEdit && parseInt(self.article.content.contentId)) {
                self.article.content.canEdit = false;
                Notifications.showErrorMessage('This document is currently being edited by another user. Please try again later.');
              }

              if(!lockData.canEditGlobal && parseInt(self.article.content.globalContentId)) {
                self.article.content.canEditGlobal = false;
                if(data.content.canEdit) {
                  Notifications.showErrorMessage('The Fortix document is currently being edited by another user you can still edit the client documentation.');
                }
                else {
                  Notifications.showErrorMessage('This document is currently being edited by another user. Please try again later.');
                }
              }

              if((self.article.content.title && self.article.content.title.length) ||
                 (self.article.content.document && self.article.content.document.length) ||
                 (self.article.content.internal && self.article.content.internal.length)){
                jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').removeClass('collapsed');
                jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').show();
              } else {
                jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').addClass('collapsed');
                jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').hide();
              }

              unifiedHelpContentBuilder.create(self.panelId);
              self.inEditMode = true;

              // Start lock time
              if(self.lockTimer) {
                clearInterval(self.lockTimer);
              }

              self.lockInstance = self.canEdit ? article.content.contentId : 0;
              self.lockGlobal = self.canEditGlobal ? article.content.globalContentId : 0;

              self.lockTimer = setInterval(
                function() {
                  jQuery.ajax(
                    '/freedom3/UnifiedHelp/ajaxLock',
                    {
                      method: 'post',
                      dataType: 'json',
                      async: true,
                      data: {
                        contentId: self.lockInstance,
                        globalContentId: self.lockGlobal,
                        app_id: sessionStorage.getItem('_unifiedHelp_appId')
                      },
                      success: function(reLockData) {
                        if(!reLockData.success) {
                          Notifications.showErrorMessage('The content lock has been lost, someone else may have edited it.');
                        }
                      }
                    }
                  );
                },
                15000
              )
            }
            else {
              Notifications.showErrorMessage('Failed to lock document for editing!');
            }
          },
          error: function() {
            jQuery(self.$el).find('i').addClass('fa-edit').removeClass('fa-spinner fa-spin');
          }
        }
      );
    },
    async cancelLock() {
      var self = this;

      // If has a lock release it
      if(self.lockTimer) {
        // Request lock
        jQuery.ajax(
          '/freedom3/UnifiedHelp/ajaxUnlock',
          {
            method: 'post',
            dataType: 'json',
            async: true,
            data: {
              contentId: self.lockInstance,
              globalContentId: self.lockGlobal,
              app_id: sessionStorage.getItem('_unifiedHelp_appId')
            }
          }
        );

        clearInterval(self.lockTimer);
        self.lockTimer = null;
      }
    },
    async editCancel() {
      var contentId = this.contentId,
          isGlobal = this.isGlobal;
      unifiedHelpContentBuilder.destroy(this.panelId);
      this.inEditMode = false;
      this.cancelLock();
      this.resetArticle();

      jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').removeClass('collapsed');
      jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').show();

      if(contentId) {
        // Reload the article to overwrite any changes
        this.showArticle(contentId, isGlobal);
      } else {
        // If no content then go back to the results
        this.showResults = true;
      }
    },
    async editSave(closeAfterSave) {
      var self = this;
      var data = {
        global: this.isGlobal,
        app_id: sessionStorage.getItem('_unifiedHelp_appId'),
      };

      if(self.article.content.canEdit) {
        // Remember the titles for save as copy
        self.original.title = this.article.content.title;

        // Read back client docs
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-notes')) {
          data.title = this.article.content.title;
        }
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-notes')) {
          data.notes = unifiedHelpContentBuilder.builder.html(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-notes'));
        }
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-document')) {
          data.document = unifiedHelpContentBuilder.builder.html(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-document'));
        }
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-internal')) {
          data.internal = unifiedHelpContentBuilder.builder.html(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-internal'));
        }
      }

      if(self.article.content.canEditGlobal) {
        self.original.globalTitle = this.article.content.globalTitle;

        // Read back global docs
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-globalDocument')) {
          data.globalTitle = this.article.content.globalTitle;
        }
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-globalDocument')) {
          data.globalDocumentGoogleURL = this.article.content.globalDocumentGoogleURL;
          data.globalDocument = unifiedHelpContentBuilder.builder.html(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-globalDocument'));
        }
        if(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-globalInternal')) {
          data.globalInternalGoogleURL = this.article.content.globalInternalGoogleURL;
          data.globalInternal = unifiedHelpContentBuilder.builder.html(document.querySelector('.unified-help-panel-' + this.panelId + ' .unified-help-content-globalInternal'));
        }
      }

      if(!this.contentId) {
        data.uris = [ unifiedHelp.getURI(false) ];
      }
      else {
        data.contentId = this.contentId;
      }

      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxSave',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: data,
          success: function(data) {
            self.isGlobal = data.isGlobal;
            self.contentId = data.contentId;

            Notifications.showSuccessMessage('Article Saved');

            // Notify other panels of the save, they can reload if they are showing the same article
            self.$dispatch('unifiedhelp-article-edited', {
              contentId: self.contentId,
              isGlobal: self.isGlobal,
              panelId: self.panelId,
              title: self.isGlobal ? self.article.content.globalTitle : self.article.content.title
            });

            // Close editor
            if(closeAfterSave) {
              self.editCancel();
            }
          },
          error: function() {
            Notifications.showErrorMessage('Failed to save');
          }
        }
      );
    },
    async deleteTopic() {
      var self = this;

      if(confirm('Do you want to delete the client documentation?')) {
        unifiedHelpContentBuilder.destroy(this.panelId);
        this.inEditMode = false;
        this.cancelLock();

        jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').removeClass('collapsed');
        jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').show();

        // Issue save
        jQuery.ajax(
          '/freedom3/UnifiedHelp/ajaxDelete',
          {
            method: 'post',
            dataType: 'json',
            async: true,
            data: {
              contentId: this.article.content.contentId,
              global: false,
              app_id: sessionStorage.getItem('_unifiedHelp_appId')
            },
            success: function() {
              self.$dispatch('unifiedhelp-article-deleted', {contentId: self.article.content.contentId, isGlobal: false});
              self.resetArticle();
            }
          }
        );
      }
    },
    async deleteGlobalTopic() {
      var self = this;

      if(confirm('Do you want to delete the Fortix documentation?')) {
        unifiedHelpContentBuilder.destroy(this.panelId);
        this.inEditMode = false;
        this.cancelLock();

        jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs').removeClass('collapsed');
        jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').show();

        // Issue save
        jQuery.ajax(
          '/freedom3/UnifiedHelp/ajaxDelete',
          {
            method: 'post',
            dataType: 'json',
            async: true,
            data: {
              contentId: this.article.content.globalContentId,
              global: true,
              app_id: sessionStorage.getItem('_unifiedHelp_appId')
            },
            success: function() {
              self.$dispatch('unifiedhelp-article-deleted', {contentId: self.article.content.globalContentId, isGlobal: true});
              self.resetArticle();
              self.editCancel();
            }
          }
        );
      }
    },
    toggleClientDocsSection: function() {
      jQuery(this.$el).toggleClass('collapsed');
      jQuery('.unified-help-panel-' + panelId + ' .unified-help-clientdocs-section').slideToggle();
    },

    createGoogleDoc: function(folderId, title, visibility, fn) {
      var id = '';

      jQuery.ajax(
        '/freedom3/UnifiedHelp/ajaxCreateGoogleDoc',
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: {
            folderId: folderId,
            title: title,
            visibility: visibility
          },
          success: function(data) {
            if(data.documentId == '') {
              Notifications.showErrorMessage('Failed to create Google Document');
            }

            fn(data.documentId);
          },
          error: function() {
            Notifications.showErrorMessage('Failed to create Google Document');
            fn('');
          }
        }
      );
    },

    addNewFortixGoogleDoc: function() {
      // Check if the folder is selected
      if(!this.academyFortixFolder) {
        Notifications.showErrorMessage('Please select a folder for the new document.');
        return;
      }

      // Check if the title is set
      if(!this.article.content.globalTitle) {
        Notifications.showErrorMessage('Please set a title for the new document.');
        return;
      }

      this.isCreatingDoc = true;

      // Create the document and store the Id
      var self = this;
      setTimeout(function() {
        self.createGoogleDoc(self.academyFortixFolder, self.article.content.globalTitle, 'public', function(docId) {
          if(docId != '') {
            self.article.content.globalDocumentGoogleURL = docId;
            self.editSave(false);
          }

          self.isCreatingDoc = false;
        });
      }, 0);
    },
    addNewInternalGoogleDoc: function() {
      // Check if the folder is selected
      if(!this.academyInternalFolder) {
        Notifications.showErrorMessage('Please select a folder for the new document.');
        return;
      }

      // Check if the title is set
      if(!this.article.content.globalTitle) {
        Notifications.showErrorMessage('Please set a title for the new document.');
        return;
      }

      this.isCreatingDoc = true;

      // Create the document and store the Id
      var self = this;
      setTimeout(function() {
        self.createGoogleDoc(self.academyInternalFolder, self.article.content.globalTitle, 'private', function(docId) {
          if(docId != '') {
            self.article.content.globalInternalGoogleURL = docId;
            self.editSave(false);
          }

          self.isCreatingDoc = false;
        });
      }, 0);
    }
  };
};
