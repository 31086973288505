import floatthead from 'floatthead';
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import sort from '@alpinejs/sort'

import "/ui/js/Components/DashboardBundle/recordedTime.js";
import "/ui/js/Components/ReportBundle/projectPriorityReport.js";
import "/ui/js/Components/unified-help/unifiedHelpComponent.js";
import "/ui/js/Components/unified-help/articleComponent.js";
import "/ui/js/Components/unified-help/searchComponent.js";

window.Alpine = Alpine;
Alpine.plugin(persist)
Alpine.plugin(sort)

jQuery(document).ready(function() {
  Alpine.start();
});